@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/_variables';
@import './styles/variables';
@import './styles/mixins';

.row {
  --bs-gutter-x: none;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: none;
  margin-right: none;
  margin-left: none;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: none;
  overscroll-behavior: none;
}

body {
  background-color: black;
}

body,
html,
.app,
#root {
  height: fit-content;
  width: 100%;
  min-width: fit-content;
  color: white;
  letter-spacing: 1px;
  position: relative;
}

hr {
  margin: unset;
  opacity: unset;
}

a {
  color: unset;
  text-decoration: unset;

  &:hover {
    color: unset;
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: black;
  background: white;
}

::selection {
  color: white;
  background: black;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: overlay;
  scroll-behavior: smooth;
}
html::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}
html::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  height: 10px;
  width: 10px;
}
.background {
  bottom: 0px;
  position: fixed;
  z-index: -10000;
  min-height: fit-content;
  min-width: 1400px;
  height: 100%;
  width: 100%;
  vertical-align: center;
}

.app {
  height: fit-content;
}
.app-main {
  @include flex-row;
  justify-content: center;
  margin-bottom: 36px;
  height: fit-content;
}
.app-main-content {
  width: 100%;
  height: fit-content;
}
.screen-banner {
  width: 98%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0px 30px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.56);
  margin: auto;
  max-width: 1280px;
}
.left-banner {
  border-left: 1px solid rgba(255, 255, 255, 0.432);
  border-radius: 30px;
  border-right: 1px solid rgba(122, 122, 122, 0.3);
}
.right-banner {
  border-right: 1px solid rgba(255, 255, 255, 0.432);
  border-top: 1px solid rgba(255, 255, 255, 0.432);
  border-radius: 0px 30px 3px 30px;
}


@media screen and (max-width: 800px) {
  .screen-banner {
    width: 95%;
    flex-direction: column;
    justify-content: center;
    padding: 56px 30px 0px 30px;
    margin: auto;
  }
  .homeScreen {
    padding-top: 0px;
  }
}
@media screen and (min-width: 800px) {
  .left-banner {
    margin-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .screen-banner {
    width: 100%;
    padding: 56px 10px 0px 10px;
    margin: unset;
    border-radius: unset;
    border: unset;
    min-width: 375px;
    max-width: 520px;
  }
  .app-main {
    margin: unset;
  }
}