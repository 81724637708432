$base-color: white;
$secondary-color: rgb(0, 74, 185);
$accent-color: red;
$subtext-color: rgba(255, 255, 255, 0.432);

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
