.top-button {
  height: 36px;
  width: 36px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.132);
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  border-radius: 5px;
  right: 6px;
  z-index: 999;
}

.top-button > i {
  color: rgb(189, 189, 189);
}

.top-button:hover {
  background-color: rgba(255, 255, 255, 0.432);
}

@media screen and (max-width: 576px) {
  .top-button {
    top: unset;
    bottom: 0px;
    right: 6px;
  }
}
