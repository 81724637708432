@import './variables';
.projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  margin-top: -40px;
}
.projects > h6 {
  display: inline-block;
  border: 1px solid white;
  padding: 10px;
  font-size: 24px;
}
.section-title-container {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  opacity: 0%;
}
.section-title-container h3 {
  font-size: 36px;
  text-align: center;
  color: $subtext-color;
  width: fit-content;
  padding: 0px 16px;
}
.section-title-hr {
  height: 1px;
  width: 100%;
  background-color: $subtext-color;
}
.project-display {
  padding: 40px;
  min-width: 400px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.project-display-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
  top: 18px;
  opacity: 0%;
}
.project-display-title {
  color: white;
  height: fit-content;
  font-size: 36px;
}
.project-display-demo {
  height: 300px;
  width: 300px;
  margin-bottom: 16px;
  background-color: rgba(255, 255, 255, 0.3);
}
.project-display-content {
  padding: 0px 10px;
  max-width: 425px;
  min-width: 320px;
  width: 100%;
  margin: 16px;
}
.project-display-content-header {
  width: 100%;
  max-width: 425px;
  margin-bottom: 0px;
}
.project-display-link-container {
  display: flex;
}
.project-display-link {
  margin-right: 8px;
  text-align: center;
  display: inline-block;
  position: relative;
  color: #fff;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  padding: 10px 0px;
  width: 150px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s linear 0s;
  background-color: rgba(175, 175, 175, 0.232);
  cursor: pointer;
  z-index: 0;

  &::before {
    content: url('https://github.com/samqchau/portfolio-v1.0.0/blob/gh-pages/pngs/link_64.png?raw=true');
    font-family: FontAwesome;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: -40px;
    width: 40px;
    height: 100%;
    background-color: rgba(197, 197, 197, 20%);
    transition: all 0.2s linear 0s, background-color 0.15s 0.15s ease-out;
    text-align: center;
    padding-top: 5px;
  }

  &:hover {
    text-indent: -30px;

    &:before {
      right: 0;
      text-indent: 0px;
    }
  }
}
.project-display-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 18px;
  padding: 24px 6px;
  background-color: rgba(83, 83, 83, 0.3);
  opacity: 0%;
  height: fit-content;
  border-radius: 5px;
  border-radius: 12px;
}
.project-display-left,
.project-display-right {
  max-width: 470px !important;
}
.project-display-right > .container {
  display: flex;
  max-width: 425px;
}
.project-display-right > .container > .container > h6 {
  margin-bottom: 0px !important;
}
.project-display-content-list {
  margin-top: 4px;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-display-content-list > li {
  list-style: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.project-display-right > .container > hr {
  display: none;
  background-color: rgb(156, 156, 156);
  height: 1px;
}

@media screen and (max-width: 992px) {
  .project-display {
    padding-bottom: 0px !important;
  }
  .project-display-right {
    max-width: 400px;
  }
  .project-display-title {
    font-size: 18px;
    text-align: center;
    width: 100%;
    max-width: 425px;
    margin-bottom: 8px;
  }
  .section-title-container h3 {
    font-size: 26px;
    margin-bottom: 16px;
  }
  .project-display-content {
    margin: 0px 0px 16px 0px;
  }
  .project-display-content-header {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 576px) {
  .project-display-right {
    background-color: unset;
    padding: unset;
  }
  .project-display-right > .container > hr {
    display: block;
  }
  .project-display-right > .container > .container {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .project-display-right > .container > .container > h6 {
    width: 100%;
    max-width: fit-content;
    display: inline-block;
  }
  .project-display-content-list > li {
    width: 100%;
    list-style: none;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .project-display-content-list {
    margin-left: 20px;
    width: fit-content;
    padding-left: 16px;
    border-left: 1px solid #ffc272;
  }
  .project-display-right > .container > .container {
    display: flex;
  }
  .section-title-container h3 {
    font-size: 1.3rem;
  }
  .project-display-content-header {
    text-align: center;
  }
}
