@mixin flex-row {
  display: flex;
}
@mixin flex-column {
  display: flex;
  flex-direction: columnn;
}
@mixin fluid {
  height: 100%;
  width: 100%;
}
