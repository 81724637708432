.contactScreen {
  display: flex;
  align-items: center;
  width: 100%;
  height: 520px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: unset;
  position: relative;
  top: 18px;
  opacity: 0%;
}
.contactScreen > h3 {
  color: #ffc272;
  margin-bottom: 8px;
  transition: 0.1s all;
  position: relative;
  font-size: 1rem;
}
.contactScreen > p {
  margin-bottom: 0px !important;
}
p.mail {
  font-size: 28px;
  padding: 4px 4px 4px 10px;
}
p.phone {
  font-size: 18px;
  padding: 4px 4px 4px 10px;
}
img.imessage {
  height: 26px;
  width: 26px;
  margin-left: 6px;
}
p.phone i {
  font-size: 22px;
  margin-right: 6px;
}
p.phone,
p.mail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
}
