@import './mixins';
@import './variables';

.header {
  width: 100%;
  min-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 25px;
  position: sticky;
  top: 0px;
  z-index: 1000;
  height: 56px;
  background-color: rgba(0, 0, 0, 0);
}
.header-nav {
  @include flex-row;
  justify-content: space-between;
  width: 100%;
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
}
.header-nav-items,
.header-nav-socials {
  display: flex;
  align-items: center;
}
.header-nav-item {
  text-transform: capitalize;
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer;
  color: rgba(256, 256, 256, 0.7);
  transition: color 0.13s linear;
  &:hover {
    color: white;
  }
}
.header-nav-item-active {
  color: white;
}
.icon-container {
  height: 40px;
  width: 40px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 50%;
  background-color: rgba(256, 256, 256, 0.4);
  box-shadow: rgba(255, 255, 255, 0.3) inset 10px 10px 5px,
    rgba(255, 255, 255, 0.3) inset -10px -10px 5px;
  transition: all 0.2s ease-in;
}
.icon-container:hover {
  background-color: rgba(240, 240, 240, 0.8);
  box-shadow: rgba(248, 248, 248, 0.829) inset 1px 1px 5px,
    rgba(255, 255, 255, 0.788) inset -1px -1px 5px;
}
.icon {
  height: 20px;
  width: 20px;
}
.gh-icon {
  height: 25px;
  width: 25px;
}

@media screen and (max-width: 800px) {
  .header {
    padding-top: 6px !important;
  }
}
@media screen and (max-width: 480px) {
  .header-nav-socials {
    position: fixed;
    top: 4px;
    flex-direction: column;
    right: 0px;
  }
  .header-nav-socials > a {
    margin: 4px 0px;
  }
  .header {
    background-color: black;
  }
}
