@import './mixins';
@import './variables';

.homeScreen {
  display: flex;
  padding-top: 100px;
  padding-left: 40px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.homeScreen-profile-picture {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  border: 1px solid rgba(256, 256, 256, 0.5);
  opacity: 0%;
  position: relative;
  top: 18px;
  background-image: url()
}
.homeScreen-banner-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  margin-left: 50px;
  margin-top: 30px;
  margin-bottom: 10px;
  opacity: 0%;
  position: relative;
  left: 18px;
}
.homeScreen-banner-content {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.homeScreen-banner-resume-button {
  padding: 20px 35px 18px 35px;
  background: linear-gradient(
    -135deg,
    rgba(163, 69, 251, 1) 0%,
    rgba(251, 189, 65, 1) 100%
  );
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  letter-spacing: 2px;
  border-radius: 2px;
  width: 250px;
}
.homeScreen-banner-resume-button:hover {
  border-radius: 36px;
}

@media screen and (max-width: 800px) {
  .homeScreen {
    padding-left: 0px;
    flex-direction: column;
  }
  .homeScreen-banner-content {
    text-align: left;
  }
  .homeScreen-banner-right {
    margin-left: 0px;
  }
}

@media screen and (min-width: 800px) {
  .homeScreen-banner {
    padding-left: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .homeScreen {
    padding-left: 0px;
  }
}
